import 'bootstrap/dist/css/bootstrap.min.css';
import ArtistsIcon from '../../images/artists.png';
import EnterpriseIcon from '../../images/enterprise.png';
import StorytellersIcon from '../../images/storytellers.png';

function Home() {
  return (
    <>
      <div style={{ padding: '30px 10px 10px 10px' }}>
        <h1 >Revolutionizing Finance with AI-Powered Insights <br />for everyone</h1>
      </div>
      <div style={{ padding: '10px 10px 30px 10px' }}>
      Unlock the potential of the financial markets with our AI-driven platform. 
      <br />From real-time data to insightful analysis, we bring the power of professional tools to every investor.
      </div>

      <div>
      </div>

      <div style={{ padding: '30px 10px 30px 10px' }}>
        <h3>One platform, unlimited possibilites </h3>Coming soon...
      </div>

      <div className="container">
        <div className="row flex">
          <div className="col-md-4">
            <img src={ArtistsIcon} style={{ height: 98, width: 98 }} />
            <h4>Market Intelligence</h4>
            <div style={{margin: "0px 0px 30px 0px"}}>
              AI-curated Financial News: <br/>Stay ahead with relevant, <br/>real-time market insights.
            </div>
          </div>
          <div className="col-md-4">
            <img src={StorytellersIcon} style={{ height: 98, width: 98 }} />
            <h4>Stocks & Options</h4>
            <div style={{margin: "0px 0px 30px 0px"}}>
            Enhance Trading with AI: <br/> Agent assisted real-time insights  <br/>for stocks and options strategies
            </div>
          </div>
          <div className="col-md-4">
            <img src={EnterpriseIcon} style={{ height: 98, width: 98 }} />
            <h4>Portfolio Management</h4>
            <div style={{margin: "0px 0px 30px 0px"}}>
            AI-Powered Portfolio Management:<br/> Smart tools for optimal investment <br/>decisions and tracking.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AppIcon from '../../images/linkedhash_icon.png';

function MyNav() {
    return (
        <>
            <Navbar collapseOnSelect expand="md">
                <Container>
                    <Navbar.Brand href="#home"><img src={AppIcon} style={{ height: 60, width: 200 }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav >
                            <Nav.Link href="https://linked-hash.gitbook.io/linkedhash/" style={{ padding: "10px 30px 10px 30px" }}>Docs</Nav.Link>
                            <Nav.Link href="https://discord.com/channels/944628298309320746/944628298833616948">Discord</Nav.Link>
                            <Nav.Link href="https://twitter.com/Linked_Hash">Twitter</Nav.Link>
                            <Nav.Item style={{ margin: "5px 0px 0px 0px" }}>
                                <Button variant="primary" style={{ padding: "5px 10px 5px 10px", fontSize: "14px", display: "none" }}>Get Started</Button>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default MyNav;
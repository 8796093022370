import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <>
      <div style={{ "padding": "30px 10px 30px 10px", "backgroundColor": "rgb(75, 75, 75)", "color": "white" }}>
        <div className="d-none d-md-block">
          <div className="row">
            <div className="col-md-1" >
            </div>
            <div className="col-md-5" style={{ "textAlign": "left" }} >
              A New Futures LLC Company
            </div>
            <div className="col-md-5" style={{ "textAlign": "right" }} >
              <a href="https://linked-hash.gitbook.io/linkedhash/about-us" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>About</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>Docs</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/contact-us" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>Contact Us</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/terms-of-use" style={{ "padding": "0px 0px 0px 0px", "textDecoration": "none", "color": "white" }}>Legal</a>
            </div>
            <div className="col-md-1" >
            </div>
          </div>
        </div>


        <div className="d-xs-block d-sm-block d-md-none">
          <div className="row ">
            <div className="col-xs-12 col-md-5 col-md-offset-1" style={{ "textAlign": "center", "margin": "0px 0px 15px 0px" }} >
              A New Futures LLC Company
            </div>
            <div className="col-xs-12 col-md-5" style={{ "textAlign": "center" }} >
              <a href="https://linked-hash.gitbook.io/linkedhash/about-us" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>About</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>Docs</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/contact-us" style={{ "padding": "0px 20px 0px 0px", "textDecoration": "none", "color": "white" }}>Contact Us</a>
              <a href="https://linked-hash.gitbook.io/linkedhash/terms-of-use" style={{ "padding": "0px 0px 0px 0px", "textDecoration": "none", "color": "white" }}>Legal</a>
            </div>
            <div className="col-md-1" >
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Footer;